import React from 'react'

import { Button, Icon } from 'v2/ui'
import * as SvgIcons from 'v2/ui/svgs'

import { useAppUserContext } from './AppUserContext'

export const HelpButton = () => {
    const { user } = useAppUserContext()

    if (user) return null

    return (
        <div style={{ position: 'fixed', bottom: '17px', right: '20px', marginLeft: '30px' }}>
            <Button
                variant="help"
                style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#ffffff',
                    boxShadow:
                        'rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px',
                }}
                buttonSize="mdDense"
                onClick={() => (window.location.href = 'mailto:support@stackerhq.com')}
            >
                <Icon svg={<SvgIcons.QuestionMarkBlack />} />
            </Button>
        </div>
    )
}
